/* Leftsidebar */

.hebrew .sidebar{
    right:0 !important;
    left:auto;
  }


.hebrew .sidebar.close .nav-links li .sub-menu {
    left: auto;
    right: 100%;
    padding: 10px 20px;
    border-radius: 6px 0 0 6px;
}
.hebrew .sidebar .nav-links li i {
    height: 50px;
    min-width: 50px;
    text-align: center;
    line-height: 50px;
    color: black;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-left: auto;
    margin-right: 8px;
}
.hebrew .toggle-arrow-btn{

    transform: rotate(180deg);
    float: left;
}
.hebrew .css-gzsczw-MuiSvgIcon-root{
    float: left;
}


/* Tab Components */

.hebrew .css-8aoy2o-MuiGrid-root{
    width: 100%;
    justify-content: end;
    margin-left:'auto';
    margin-right: 10%;
    z-index: 1;
    position: -webkit-sticky;
    position: sticky;

}

/* interactive narrated button */


/* :root {
    --activeSwitch: rgb(206, 0, 52);
    --textColor1:  rgb(206, 0, 52);
    --switchColor: #FFFFFF;
    --textColor:#FFFFFF;
    --shadow: #000000;
  } */
  
  .hebrew .switch {
    width: 200px;
    margin-left: 20px !important;
    position: relative;
    overflow: hidden;
    border-radius: 50px;
    background: var(--textColor);
    border: 1px solid var(--switchColor);
  }
  
  .hebrew .switch > span {
  width: 100px;
  height: 35px;
  display: table-cell;
  position: relative;
  z-index: 100;
  text-align: center;
  vertical-align: middle;
  color: var(--textColor1); 
  cursor: pointer;
  transition: color ease-in 0.23s 0.09s;
}

.hebrew .switch > span.on {
  color: var(--textColor);
}

.hebrew .switch_bg {
  width: 110px;
  height: 45px;
  position: absolute;
  top: 0;
  left: -11px;
  transform: skew(-30deg);
  transition: all ease-in 0.23s;
  background: var(--switchColor);
  box-shadow: 0px 0px 1px 1px var(--shadow);
}

.hebrew .switch_bg.right {
  left: 95px;
  transition: all ease-in 0.23s;
}

.hebrew .radio_btns {
  display: none;
}

/* search bar */

.hebrew .css-6ds2f6-MuiInputBase-root{
    margin-right: 50px;
}

.Dialog{
  direction:rtl;
}

.hebrew .apicontainer{
  padding: 2%;
  padding-right:20%;
  padding-left:5%;
  padding-top:8%;
  width:100%;
}

.hebrew .modalpopup{
  direction:rtl !important;
}

.hebrew .rtl-modal{
  direction:rtl !important;
}

.modal{

  direction: rtl;
}