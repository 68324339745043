.settingsModal {
    position: absolute !important;
    top: 15%;
    left: 25%;
    background-color: #fff;
    border-radius: 4px;
    width: 50%;
    height: auto;
    max-width: 50%;
    max-height: 100%;
    flex-direction: column;
    overflow: auto;
}
.settingsModalMobile{
    position: absolute !important;
    top: 5%;
    left: 5%;
    background-color: #fff;
    border-radius: 4px;
    width: 90%;
    height: auto;
    max-width: 90%;
    overflow: auto;
    flex-direction: column;
}
.settingsModalTablet{
    position: absolute !important;
    top: 15%;
    left: 10%;
    background-color: #fff;
    border-radius: 4px;
    width: 80%;
    height: auto;
    max-width: 80%;
    overflow: auto;
    flex-direction: row;
}
.userdetails {

    margin-top: 1;
    background-color: #e9e9e999;
    padding: 20px 20px 40px 20px;
    border-radius: 5px;
    width: 100%;
}

.Apidetails {
    margin-top: 1;
    background-color: #e9e9e999;
    padding: 20px 20px 40px 20px;
    border-radius: 5px;
    width: 100%;
    text-align: left;
}

.favourotetextbox {
    width: 100%;
    padding: 1;
    background-color: #e9e9e999;
    border-radius: 2;
    font-size: '0.875rem';
    font-weight: '700';
    text-align: 'center';
    height: '70%';
}

.emaildetails {
    margin-top: 1;
    background-color: #e9e9e999;
    padding: 20px 20px 20px 20px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
}

.emaildetailsbanner {
    margin-top: 1;
    background-color: #e9e9e999;
    padding: 20px 20px 20px 20px;
    border-radius: 5px;
    max-width: 100%;
    text-align: center;
}

.EmailModal {
    position: absolute !important;
    top: 35%;
    left: 25%;
    background-color: #fff;
    border-radius: 4px;
    width: 50%;
    height: auto;
    max-width: 1100px;
    flex-direction: column;
}

.EmailModalTablet {
    position: absolute !important;
    top: 35%;
    left: 20%;
    background-color: #fff;
    border-radius: 4px;
    width: 60%;
    height: auto;
    max-width: 600px;
    flex-direction: column;
}

.EmailModalMobile {
    position: absolute !important;
    top: 35%;
    left: 5%;
    background-color: #fff;
    border-radius: 4px;
    width: 90%;
    height: 30%;
    max-width: 600px;
    flex-direction: column;
}

.contentemaildetails {
    margin-top: 1;
    background-color: #e9e9e999;
    padding: 20px 20px 20px 20px;
    border-radius: 5px;
    width: 100%;
    height: 100%;
}

.messagecontent {
    padding-top: 5%;
    width: 100%;
    flex-direction: column;
}
.messagebox {
    width: 50%;
    display: 'flex';
}
.UploadlogoModal {
    position: absolute !important;
    top: 25%;
    left: 25%;
    background-color: #fff;
    border-radius: 4px;
    width: 50%;
    height: auto;
    max-width: 60%;
    max-height: 50%;
    flex-direction: column;
}
.UploadlogoModalMobile {
    position: absolute !important;
    top: 20%;
    left: 5%;
    background-color: #fff;
    border-radius: 4px;
    width: 90%;
    height: auto;
    max-width: 90%;
    max-height: 80%;
    flex-direction: column;
}
.UploadlogoModalTablet {
    position: absolute !important;
    top: 25%;
    left: 20%;
    background-color: #fff;
    border-radius: 4px;
    width: 60%;
    height: auto;
    max-width: 60%;
    max-height: 100%;
    flex-direction: column;
}
.smsModal{
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    width: 25%;
    height: 28%;
    max-width: 500px;
    flex-direction: column;
}

.BuyNowModal{
    position: absolute !important;
    top: 15%;
    left: 40%;
    background-color: #fff;
    border-radius: 4px;
    width: 30%;
    height: 70%;
    /* max-width: 500px; */
    flex-direction: column;
    overflow: auto;
}
.BuyNowModalMobile{
    position: absolute !important;
    top: 5%;
    left: 5%;
    background-color: #fff;
    border-radius: 4px;
    width: 90%;
    height: 90%;
    max-width: 90%;
    flex-direction: column;
    overflow: auto;
}
.BuyNowModalTablet{
    position: absolute !important;
    top: 10%;
    left: 15%;
    background-color: #fff;
    border-radius: 4px;
    width: 70%;
    height: auto;
    max-height: 80%;
    /* max-width: 500px; */
    flex-direction: column;
    overflow: auto;
}
.PlayUserModal{
    position: absolute !important;
    top: 25%;
    left: 12.5%;
    background-color: #fff;
    border-radius: 4px;
    width: 75%;
    height: auto;
    max-height: 90%;
    flex-direction: column;
    overflow: auto;
}

.PlayUserModalMobile{
    position: absolute !important;
    top: 5%;
    left: 5%;
    background-color: #fff;
    border-radius: 4px;
    width: 90%;
    height: auto;
    max-height: 90%;
    flex-direction: column;
    overflow: auto;
}

/* .UploadUserModal{
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    width: 55%;
    height: 45%;
    flex-direction: column;
} */

.UploadUsereditModal{
    position: absolute !important;
    top: 25%;
    left: 20%;
    background-color: #fff;
    border-radius: 4px;
    width: 60%;
    height: auto;
    max-height: 80%;
    flex-direction: column;
    overflow: auto;
}
.UploadUsereditModalMobile{
    position: absolute !important;
    top: 15%;
    left: 5%;
    background-color: #fff;
    border-radius: 4px;
    width: 90%;
    height: auto;
    flex-direction: column;
    overflow: auto;
}
.UploadUsereditModalTablet{
    position: absolute !important;
    top: 20%;
    left: 10%;
    background-color: #fff;
    border-radius: 4px;
    width: 80%;
    height: auto;
    max-height: 80%;
    flex-direction: column;
    overflow: auto;
}
.UploadUserDeleteModal{
    position: absolute !important;
    top: 35%;
    left: 25%;
    background-color: #fff;
    border-radius: 4px;
    width: 50%;
    height: auto;
    flex-direction: column;
    overflow: auto;
}
.UploadUserDeleteModalMobile{
    position: absolute !important;
    top: 32.5%;
    left: 5%;
    background-color: #fff;
    border-radius: 4px;
    width: 90%;
    height: auto;
    flex-direction: column;
    overflow: auto;
}
.UploadUserDeleteModalTablet{
    position: absolute !important;
    top: 50%;
    left: 50%;
    background-color: #fff;
    border-radius: 4px;
    width: 35%;
    height: auto;
    flex-direction: column;
}
/* .control{

    display:none;
} */

media-time-slider{

    display:none;
}


.apicontainer{
    padding: 2%;
    padding-left:20%;
    padding-right:5%;
    padding-top:5%;
    width:100%;
}

.apicontainerMobile{
    padding: 2%;
    padding-left:5%;
    padding-right:5%;
    padding-top:5%;
    width:100%;
}
.hebrew.apicontainer{
    padding: 2%;
    padding-right:20%;
    padding-left:5%;
    padding-top:5%;
    width:100%;
}