/* //header */
.ToolBar{
  min-height: 50px !important;
}

/* Footer */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100%;
  position: relative;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #8b8585;
  color: #fff;
}

.footer-content {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.footer-nav {
  list-style: none;
  display: flex;
  /* padding: 0; */
}

.footer-nav li {
  margin-right: 20px;
}

.footer-nav li:last-child {
  margin-right: 0;
}

.footer-nav li a {
  color: #fff;
  text-decoration: none;
}


/* .textfield{
  padding:10px 14px !important;
  height: 1.4375em !important;
} */


.intl-tel-input {
  display: block !important;
}

.iti{
  display: block !important;
}

.subscribe-applyCode{
  background-color: #eee !important;
  color: #555 !important;
  width: 100% !important;
  text-align: center !important;
  font-weight: 500 !important;
  padding: 7px 5px !important;
  border: 1px solid #ccc !important;
  text-transform: capitalize !important;
}

.MuiTabs-indicator{
  display: none;
 
}


/* / footer css / */

.css-354xmf-MuiToolbar-root{
  min-height: 50px !important;
}

.sitelogo{
  max-height: 45px;
  display: none;
}

/* / subscrition / */
.visaIcon{
  max-height: 40px;
}
.OneTimePin{
  cursor: pointer; text-decoration: underline; color:#337ab7;
}

.OneTimePinGrid{
  padding-top: 30px;
  padding-bottom: 30px;
}


.OneTimePinDialog .css-ypiqx9-MuiDialogContent-root{
  overflow-y: visible;
}

.OneTimePinDialog .css-tlc64q-MuiPaper-root-MuiDialog-paper{
  overflow-y: inherit;
}

/*for Button css*/
.btn{
  text-transform: capitalize !important;
}

/*Modal Dialog Title */
.custom-dialog-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    font-size: 18px !important;
    padding: 10px 15px !important;
}
.custom-dialog-footer{
  border-top:1px solid #ddd;
  padding: 20px 0px;
}
.loginLogo{
  width: 100%;
}

/* dashboard header tab */
.css-isvzdk-MuiTabs-root .MuiTab-root {
  
  padding: 10px 76px !important;
  margin: 0px 10px;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 16px;
}



.css-1oqqzyl-MuiContainer-root {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left :0px !important;
    padding-right:0px !important;
}


/* search bar design */

.searchbar{
 
  background-color: #fff !important;
}
.searchicon{
  color: #080707 !important;
}

.inputbase{
color:#0a0a0a !important;
font-weight: bold !important;
/* background-color: #fff !important; */

}

.searchbarMobile{
  position: absolute;
  width:90vw!important;
  background-color: #fff !important;
}
.searchiconMobile{
  color: #080707 !important;
}

.inputbaseMobile{
color:#0a0a0a !important;
font-weight: bold !important;
/* background-color: #fff !important; */

}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

.App {
  font-family: sans-serif;
  text-align: center;
}


/* sidebar */

/* .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  z-index: 1200;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  outline: 0;
  left: 0;
  border-right:  0px !important;;
} */

.css-1v8x7dw {
  overflow: auto;
  padding-top: 22px !important;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 36px !important;
  color: rgba(0, 0, 0, 0.54);
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

/*dashboard css*/
.css-1t4lqmc-MuiButtonBase-root-MuiTab-root{
  padding: 0px !important;
}

.viewTab{
  height: 50px !important;
  overflow-clip-margin: unset!important;
  overflow: unset; 
}
.css-9b4s65{
  margin-top: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 5px;
  padding-bottom: 15px;
}

@media (max-width: 600px) {
  .css-zw3mfo-MuiModal-root-MuiDialog-root{
    z-index: 0 !important;
  }
  .css-cyxlny-MuiPaper-root-MuiDialog-paper{
    margin: 0px !important;
  }
  .MobileSubscribe .css-cyxlny-MuiPaper-root-MuiDialog-paper{
    width: 100% !important;
  }
  .MobileSubscribe .css-1et4q11-MuiTypography-root-MuiDialogTitle-root{
    margin-top: 20px !important;
  }
  .MobileSubscribe .css-78trlr-MuiButtonBase-root-MuiIconButton-root{
    display: none !important;
  }

  .DialogActionsMobileSubscribe{
    padding-bottom: 70px !important;
  }
  .MobileSubscribe .css-hlj6pa-MuiDialogActions-root{
    justify-content: center !important;
  }
  .css-1ijlwty-MuiPaper-root-MuiAppBar-root{
    z-index: 0 !important;
    position: fixed !important;
    bottom:0 !important;
  }
}

/* tab */
/* .tabs{
  justify-content: right!important;
  position: absolute !important;
  width: 100vw;
  max-width:100vw;
  top:0px!important;
  left: 0px !important;
} */


/* .css-sc1cgt-MuiPaper-root-MuiAppBar-root{

  position: fixed;
  bottom:0 !important;
} */


/* switch */

:root {
  --activeSwitch: rgb(206, 0, 52);
  --textColor1: #FFFFFF;
  --switchColor: #FFFFFF;
  --textColor: rgb(206, 0, 52);
  --shadow: #000000;
}

.switch {
  width: 200px;
  margin-left: 20px !important;
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  background: var(--switchColor);
  border: 1px solid var(--textColor);
}

.switch > span {
  width: 100px;
  height: 35px;
  display: table-cell;
  position: relative;
  z-index: 100;
  text-align: center;
  vertical-align: middle;
  color: var(--textColor1); 
  cursor: pointer;
  transition: color ease-in 0.23s 0.09s;
}

.switch > span.on {
  color: var(--textColor);
}

.switch_bg {
  width: 110px;
  height: 45px;
  position: absolute;
  top: 0;
  left: -11px;
  transform: skew(-30deg);
  transition: all ease-in 0.23s;
  background: var(--activeSwitch);
  box-shadow: 0px 0px 1px 1px var(--shadow);
}

.switch_bg.right {
  left: 95px;
  transition: all ease-in 0.23s;
}

.switchMobile {
  width: 200px;
  margin-left: 20px !important;
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  background: var(--switchColor);
  border: 1px solid var(--textColor);
}

.switchMobile > span {
  width: 100px;
  height: 35px;
  display: table-cell;
  position: relative;
  z-index: 100;
  text-align: center;
  vertical-align: middle;
  color: var(--textColor1); 
  cursor: pointer;
  transition: color ease-in 0.23s 0.09s;
}

.switchMobile > span.on {
  color: var(--textColor);
}

.switchMobile_bg {
  width: 110px;
  height: 45px;
  position: absolute;
  top: 0;
  left: -11px;
  transform: skew(-30deg);
  transition: all ease-in 0.23s;
  background: var(--activeSwitch);
  box-shadow: 0px 0px 1px 1px var(--shadow);
}

.switchMobile_bg.right {
  left: 95px;
  transition: all ease-in 0.23s;
}

.radio_btns {
  display: none;
}	
/*for slider */	
.slidetrimag{	
  display: block;
  max-width: 100%;
  height: auto;	
}	
.slick-prev, .slick-next {	
  font-size: 0;	
  line-height: 0;	
  position: absolute;	
  top: 50%;	
  display: block;	
  width: 30px !important;	
  height: 30px !important;
  cursor: pointer;	
  color: white !important;	
  border: none;	
  outline: none;	
  border-radius: 3px;	
  background: #444444 !important;
  z-index: 3;	
}	
.slick-prev {	
  left: -30px !important;	
}	
.slick-next {	
  right: -30px !important;	
}	
.slick-prev:hover{	
  background-color: rgb(206, 0, 52) !important;	
  color: #fff !important;	
}	
.slick-next:hover {	
  background-color: rgb(206, 0, 52) !important;	
  color: #fff !important;	
}	
.image-content-position {	
  position: absolute !important;	
}	
.hot-spot {	
  background-color: #DC0037;	
}	
.hot-spot {	
  width: 20px;	
  height: 20px;	
  background-color: rgb(206 0 52);	
  border-radius: 50%;	
  position: absolute;	
  top: 50%;	
  left: 50%;	
  transform: translate(-50%, -50%);	
  z-index: 1;	
  opacity: 0.8;	
  z-index: 1;	
  cursor: pointer;	
}	
.hot-spot:focus {	
  border: 2px solid #DC0037;	
}	
.hot-spot:hover {	
  border: 2px solid #DC0037;	
}	
.hot-spot:hover:after {	
  cursor: pointer;	
  opacity: 1;	
  width: 30px;	
  height: 30px;	
  opacity: 1;	
}	
.hot-spot:after {	
  border: 2px solid #DC0037;	
}	
.hot-spot:after {	
  content: '';	
  position: absolute;	
  top: 50%;	
  left: 50%;	
  transform: translate(-50%, -50%);	
  width: 20px;	
  height: 20px;	
  border-radius: 50%;	
  opacity: 0;	
  transition: 0.2s all;	
}	
.pop-over-menu {	
  position: absolute;	
  top: 100%;	
  left: 50%;	
  transform: translateX(-50%);	
  background-color: white;	
  border: 1px solid #ccc;	
  padding: 10px;	
  display: none;	
  z-index: 1;	
}	
.hot-spot:hover .pop-over-menu {	
  display: block;	
}	
.slick-dots li{	
  margin: 0px 15px !important;	
  width: auto !important;	
  height: auto !important;	
}	
.slick-dots{	
  bottom: 0px !important;	
  position: relative !important;	
}	


@media (min-width: 1200px){
  .container {
      width: 100% !important;
  }
}

@media screen and (max-width: 767px){
  .container {
      width: 100% !important;
  }
  .hot-spot {
      width: 10px;
      height: 10px;
  }
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255); /* Add a semi-transparent background color */
  z-index: 99999; /* Adjust the z-index as needed */
}

.spinner {
  width: 70px; 
  height: 70px; 
}

.navbarheader{
  position: fixed;
  top:0;
  bottom:auto;
  height: auto;
  width: 100%;
  z-index: 15;
}

.leftSidebar{
  /* width: 260px; */
  height: 1000px;
  background-color: rgb(228, 228, 228);
}
.mainContent{
  padding-top: 70px;
  -webkit-transition: width 0.25s ease, margin 0.25s ease;
  transition: width 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-height: calc(100vh - 80px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 5%;
}

.containerContent{
  max-width: 1400px!important;
}
/* For Carousel in Mobile dashboard */

.horizontal-scroll-wrapper{
  position:absolute;
  display:block;
  top:0;
  left:0;
  width:80px;
  max-height:500px;
  margin:0;
  background:#abc;
  overflow-y:auto;
  overflow-x:hidden;
  transform:rotate(-90deg) translateY(-80px);
  transform-origin:right top;
}
.horizontal-scroll-wrapper > div{
  display:block;
  padding:5px;
  background:#cab;
  transform:rotate(90deg);
  transform-origin: right top;
}

.carPartsWidth{
  display: flex!important;
  flex-direction: column!important;
  width: 90%!important;
  height: 200px!important;
  max-height:220px ;
  max-width: 100%;
}
.carPartsWidthMobile{
  display: flex!important;
  flex-direction: column!important;
  width: 100%!important;
  height: 200px!important;
  max-height:220px ;
  max-width: 300px;
  overflow-x: hidden;
}
/*for afterlogin dashborad*/
/* tree view */

.css-ahj2mt-MuiTypography-root {
  margin: 0;
  font-family: "Arial";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  /* padding-bottom: 44px !important;
  text-align: center !important; */
}

.css-i07aef {
  width: 250px !important;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 16px;
  margin-top: 32px;
  overflow: hidden;
}

/* .css-1lym95h-MuiGrid-root{
  justify-content: center !important;
} */
/* .css-rqglhn-MuiTable-root{
  width: 380px !important;
} */
.treeview-container {
  width: 335px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
  overflow-x: hidden !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important; 
}
/* card poster view */

.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
}

.card12 {
    position: relative;
    width: 325px;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.poster {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.poster::before {
    content: '';
    position: absolute;
    bottom: -45%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: .3s;
}

.card12:hover .poster::before {
    bottom: 0;
}

.poster img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .3s;
}

.card12:hover .poster img {
    transform: scale(1.1);
}

.details {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: auto;
    /* padding: 1.5em 1.5em 2em; */
    padding: 0.3em;
    transition: .3s;
    color: #fff;
    z-index: 2;
}

.card12:hover .details {
    bottom: 0;
}

/* E-mail */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #dc0037 !important;
}

/* Tab */
/* .css-1t4lqmc-MuiButtonBase-root-MuiTab-root {
  min-width: 55px !important;
} */

/* Tab icons to fit the width of screen */
.tabicons{
  min-width: 16.7vw!important;
}

/* For carousel arrows to hide when they reach last element of carousel */
.slick-disabled{
  opacity: 0.4;
}


/* 
.MuiTableCell-root {
  border: none !important;
} */

.footer{
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0) !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  top: 0;
  /* left: 13% !important; */
  /* right: 0; */
  background-color: #1976d2;
  color: #fff;
  background-color: rgb(228, 228, 228);
  top: auto;
  bottom: 0;
  text-transform: capitalize;
  position: fixed;
}
/* For Sidebar Components */

.css-13u2gni-MuiGrid-root{
  z-index: 0 !important;
}
/* for media player */

.media-time-slider {
  display: block !important;
}


/* .child-icons{
   margin-right: 80%;
} */



/* .css-cyxlny-MuiPaper-root-MuiDialog-paper{

  direction:rtl;
} */


.loopdetails{
  width:100%;
  padding-left:10%;
}
/* Styles for RTL direction */
.rtl-modal {
  direction: rtl;
  /* Add other RTL-specific styles */
}

/* Styles for LTR direction */
.ltr-modal {
  direction: ltr;
  /* Add other LTR-specific styles */
}

.partIcons img:hover{
  height: 100%!important;
  width: 100%!important;
  filter: invert(15%) sepia(65%) saturate(4822%) hue-rotate(334deg) brightness(97%) contrast(116%);
}
.partIcons img{
  transition: width 0.5s, height 0.5s;
  background-color: transparent!important;
  position: relative!important;

}
.partIcons div{
  display: flex;
  align-items: center!important;
  justify-content: center!important;
  vertical-align: center!important;

}

.partIcons{
  align-items: center!important;
}

/* Dashboard Tab pages */
.stickyHeading{
  position: sticky;
  top: 100px;
  background-color: white;
  z-index: 3;
}

