.manageuser{
        margin-top: 1;
        background-color: #e9e9e999;
        padding: 20px 20px 20px 20px;
        border-radius: 5px;
        width: 100%;
        text-align: center;
}
.pagination{
    padding-left: 8px;
    padding-right: 24px;
    text-align: center !important;
    -webkit-text-align-last: right;
    text-align-last: right;
}
.UserModal {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    width: 100%;
    height: 55%;
    max-width: 1200px;
    flex-direction: column;
}

.UserModalMobile {
    position: absolute !important;
    top: 2.5%;
    left: 2.5%;
    background-color: #fff;
    border-radius: 4px;
    width: 95%;
    height: auto;
    max-height: 97%;
    max-width: 1200px;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-y: auto;
}

.UserModalTablet {
    position: absolute !important;
    top: 10%;
    left: 5%;
    background-color: #fff;
    border-radius: 4px;
    width: 90%;
    height: auto;
    overflow: auto;
    max-height: 80%;
    max-width: 1200px;
    flex-direction: row;  
}


.column{
    text-align: left !important;
}

.up-in-toggle {
  padding: 4%;
}

.up-in-toggle input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 0px;
  border: 0;
  overflow: hidden;
  float:left;
}

.up-in-toggle input:checked + label {
  background: rgb(62, 193, 182);
  background: linear-gradient(
    0deg,
    rgba(62, 193, 182, 1) 0%,
    rgba(98, 227, 204, 1) 100%
  );
  color: white;
  font-weight: 500;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.09), 0 6px 6px rgba(0, 0, 0, 0.13);
}

.up-in-toggle label {
  display: inline-block;
  width: 60px;
  background-color: rgb(73, 90, 111);
  color: rgb(165, 173, 183);
  text-align: center;
  font-size: 0.8rem;
  padding: 6px 6px;
  transition: all 0.1s ease-in-out;
}

.up-in-toggle label:hover {
  cursor: pointer;
}

.up-in-toggle label:first-of-type {
  border-radius: 15.5px 0 0 15.5px;
}

.up-in-toggle label:last-of-type {
  border-radius: 0 15.5px 15.5px 0;
}


/* location modal */

.LocationModal{
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    width: 55%;
    height: auto;
    max-height: 90%;
    overflow-y: auto;
    max-width: 70%;
    flex-direction: column;
}

.LocationModalMobile {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    width: 95%;
    height: auto;
    max-height: 95%;
    max-width: 1200px;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-y: auto;
}

.LocationModalTablet {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    width: 90%;
    height: auto;
    max-height: 90%;
    max-width: 1200px;
    flex-direction: row;
    overflow-y: auto;

}


.LocationClassModal {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  width: 40%;
  height: auto;
  max-width: 1200px;
  flex-direction: column;
}

.LocationClassModalMobile {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  width: 90%;
  height: auto;
  max-height: 97%;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: auto;
}

.LocationClassModalTablet {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  width: 90%;
  height: auto;
  overflow: auto;
  max-height: 80%;
  overflow-y: auto;
  flex-direction: column;  
}

.AssignModal{
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  height: auto;
  max-width: 70%;
  flex-direction: column;
}

.AssignModalMobile {
  position: absolute !important;
  top: 2.5%;
  left: 2.5%;
  background-color: #fff;
  border-radius: 4px;
  width: 95%;
  height: auto;
  max-height: 95%;
  max-width: 1200px;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: auto;
}

.AssignModalTablet {
  position: absolute !important;
  top: 5%;
  left: 5%;
  background-color: #fff;
  border-radius: 4px;
  width: 90%;
  height: auto;
  max-height: 90%;
  max-width: 1200px;
  flex-direction: row;
  
}

.mainview.open {
  padding-left: 20% !important;
}

.mainview.close{

  width:100%;
}