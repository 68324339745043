/* Styling for the AppBar */
.AppBar {
    background-color: rgb(228, 228, 228) !important;
    height: 50px;
  }
  
  /* Styling for the MenuIcon (hamburger icon) */
  .MenuIcon {
    margin-left: auto !important;
  }
  
  /* Styling for the Menu */
  .Menu {
    min-width: 250px;
  }
  
  /* Styling for the Tabs on larger screens */
  .Tabs {
    color: black;
  }
  
  /* Styling for the Tab items on larger screens */
  .Tab {
    padding: 10px 80px;
    margin: 0px 10px;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 14px;
  }
  
  /* Styling for the Tab items on smaller screens */
  @media (max-width: 600px) {
    .Tab {
      padding: 10px 20px;
    }
  }
  

  /* Styles for tablet view */
@media (min-width: 601px) and (max-width: 959px) {
    /* Customize the menu icon for tablet view */
    .MuiIconButton-root {
      color: black;
      font-size: 30px;
      padding: 12px;
    }
  }

  /* Mobile View MenuItem */
  .MenuItem{
    border-bottom: 1px solid #d0d0d0 !important;
  }
  .MenuItem :hover{
    color: #cc0033 !important;
  }
  .MuiDrawer-paper {
    width: 270px;
  }
