/* Google Fonts Import Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
/* *{
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
} */
.sidebar{
  position: fixed;
  top: 50.5px;
  left: 0;
  height: 100%;
  width: 15%;
  background: rgb(228, 228, 228);
  z-index: 100;
  transition: all 0.5s ease;
}
.sidebar.close2{
  width: 78px;
}

.open2{
  width:250px!important;
}

.mobileyes{
  display: none!important;
}
.mobileno{
  display: block!important;
}
/* .sidebar .logo-details{
  height: 0px;
  width: 0%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i{
  font-size: 30px;
  color: #851d1d;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name{
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name{
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
} */
.sidebar .nav-links{
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}
.sidebar.close2 .nav-links{
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar{
  display: none;
}
.sidebar .nav-links li{
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .nav-links li:hover{
  background: rgb(228, 228, 228);
}
.sidebar .nav-links li .iocn-link{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close2 .nav-links li .iocn-link{
  display: block
}
.sidebar .nav-links li i{
  height: 50px;
  min-width: 50px;
  text-align: center;
  line-height: 50px;
  color: black;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 8px;
}
.sidebar .nav-links li i.rotated{
  transform: rotate(-180deg);
  line-height: 60px;
}
.sidebar.close2 .nav-links i.arrow{
  display: none;
}
.sidebar .nav-links li span{
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li span .link_name{
  font-size: 17px;
  font-weight: 400;
  color: black;
  transition: all 0.4s ease;
  margin-bottom: 7px !important;
  width:80%;
}
.sidebar.close2 .nav-links li span .link_name{
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu{
  padding: 6px 6px 14px 55px;
  margin-top: -10px;
  background: rgb(228, 228, 228);
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu{
  display: block;
}
.sidebar .nav-links li .sub-menu span{
  color: black;
  font-size: 15px;
  font-weight: bolder;
  padding: 8px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu span:hover{
  opacity: 1;
}
.sidebar.close2 .nav-links li .sub-menu{
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close2 .nav-links li:hover .sub-menu{
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name{
  display: none;
}
.sidebar.close2 .nav-links li .sub-menu .link_name{
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank{
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank{
  top: 50%;
  transform: translateY(-50%);
}

.one {
  width: 80%;
  margin-left: 10%;
  background-color: #888;
  height: 400px;
}

.sidebar .profile-details{
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #888;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar .close2 .profile-details{
  background: none;
}
.sidebar .close2 .profile-details{
  width: 78px;
}
.sidebar .profile-details .profile-content{
  display: flex;
  align-items: center;
}
.sidebar .profile-details img{
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar .close2 .profile-details img{
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job{
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar .close2 .profile-details i,
.sidebar .close2 .profile-details .profile_name,
.sidebar .close2 .profile-details .job{
  display: none;
}
.sidebar .profile-details .job{
  font-size: 12px;
}
.home-section{
  position: relative;
  background: #E4E9F7;
  height: 100vh;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
}
.sidebar .close2 ~ .home-section{
  left: 78px;
  width: calc(100% - 78px);
}
.home-section .home-content{
  height: 60px;
  display: flex;
  align-items: center;
}
.home-section .home-content .bx-menu,
.home-section .home-content .text{
  color: #11101d;
  font-size: 35px;
}
.home-section .home-content .bx-menu{
  margin: 0 15px;
  cursor: pointer;
}
.home-section .home-content .text{
  font-size: 26px;
  font-weight: 600;
}
@media (max-width: 420px) {
  .sidebar.close2 .nav-links li .sub-menu{
    display: none;
  }
}
.bx bx-grid-alt{
    color:black !important;
}
.sidebar-toogle-arrow {
  background-color: #c1c1c1;
}
/* .Downicon{
  float:right !important;
} */

.bx.bxs-chevron-down.arrow{
  float: right;
  margin: 0;
  /* width:20%; */
  /* text-align: right; */
}

.toggle-arrow-btn{
  transform: rotate(180deg);
  float:right;
}

.bx bxs-chevron-down arrow-btn{

  float: right;
  margin: 0;
  width:20%;
  text-align: right;
  transform: rotate(180deg);

}

.css-gzsczw-MuiSvgIcon-root{

  float: left;
}

/* .arrow.rotated {
  transform: rotate(180deg);
} */
