.UsageReportContainer{
    margin-top: 1;
    background-color: #e9e9e999;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
}
.UsageReportContainerMobile{
    margin-top: 1;
    background-color: #e9e9e999;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
}

.UsageReportContainerTable{
    margin-top: 1;
    background-color: #e9e9e999;
    padding: 20px 20px 20px 20px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
}

.UsageReportContainerTableMobile{
    margin-top: 1;
    background-color: #e9e9e999;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
}

.ExportButtonMobile{
    white-space: nowrap;
    width: 250px!important;
}
.ExportButton{
    white-space: nowrap;
    width: 250px!important;
}

.ExportButtonTablet{
    white-space: nowrap;
    width: 250px!important;
}